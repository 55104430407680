<template>
  <EmptyWidgetPage
    v-if="showEmptyPage"
    :has-error="hasError"
    @generate-widget-snippet="generateWidgetSnippet"
  />
  <MainPage
    v-else
    :show-footer="true"
    heading="Tracking Widget"
    tagline="Improve your customers experience by allowing them to keep track of their delivery."
  >
    <template #body>
      <div v-if="isLoading">
        <ProgressSpinner />
      </div>
      <div v-else>
        <Divider />
        <div class="widget-page-content">
          <div class="widget-instructions__container">
            <div class="widget-instructions__header">
              <div class="widget-instructions__icon-container">
                <i class="pi pi-code widget-instructions__icon" />
              </div>
              <div class="widget-instructions__header-text">
                Embedded Tracking
              </div>
            </div>
            <div class="widget-instructions__text">
              <p>To display the tracking widget on your website:</p>
              <ol>
                <li>
                  Paste the embed code inside the &lt;head&gt; tag of your
                  website.
                </li>
                <li>
                  Specify where on the webpage the widget should be displayed by
                  replacing <b>&lt;add-ID-here&gt;</b> in the code with the ID
                  of the desired container.
                </li>
              </ol>
              <p>
                Once complete, the tracking widget will appear inside the
                specified container.
              </p>
            </div>
          </div>
          <div class="card card-body snippet-code__container">
            <ErrorBar
              v-if="hasError"
              :error-message="WIDGET_ERROR_MESSAGE"
              :closable="false"
            />
            <div v-else class="snippet-code__content">
              <span class="snippet-code__header">
                Below is the embed code for the tracking widget:
              </span>
              <div class="card card-body snippet-code__code-container">
                <div class="snippet-code__code" data-cy="code-block">
                  <highlightjs language="javascript" :code="formattedSnippet" />
                </div>
              </div>
              <div class="widget-page-footer">
                <WarningText
                  v-if="isNonProdCodeSnippet"
                  warning-text="Note: This code is for
                  testing and differs from the live version."
                />
                <Button
                  :disabled="isCopied"
                  class="p-button snippet-code__button"
                  data-cy="copy-button"
                  @click="copySnippet"
                >
                  <i class="pi pi-copy"></i> {{ copyButtonText }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import MainPage from "@/components/MainPage.vue";
import Divider from "primevue/divider";
import { computed, ref, watchEffect } from "vue";
import Button from "primevue/button";
import { useCopiedState, useDomainMapping } from "@/services/utils/utils";
import ProgressSpinner from "primevue/progressspinner";
import WidgetApiRequests from "@/components/widget/helpers/apiRequests";
import EmptyWidgetPage from "@/components/widget/EmptyWidgetPage";
import { useCompanyStateManagement, useQueryCompanyState } from "@/queries";
import ErrorBar from "@/components/ErrorBar.vue";
import { WIDGET_ERROR_MESSAGE } from "@/components/widget/helpers/constants";
import WarningText from "@/components/WarningText.vue";
import { PROD_ENV } from "@/constants";
const { invalidateCompanyState } = useCompanyStateManagement();

const isLoading = ref(false);
const codeSnippet = ref("");

const { data, isFetched } = useQueryCompanyState();

const timelineWidgetEnabled = computed(() => {
  return isFetched.value && data.value.hasGeneratedWidgetCode;
});

const showEmptyPage = computed(() => {
  return !timelineWidgetEnabled.value;
});

const { copyButtonText, changeStateOnClick, isCopied } = useCopiedState(
  "Copy Code",
  "Copied",
);

const formattedSnippet = computed(() => {
  // Script tag was in conflict with VueJS. We need to escape that, however then eslist is screaming.
  // Because of that we need to disable that rule here.
  /* eslint-disable no-useless-escape */
  return `
<script>
  ${codeSnippet.value}
<\/script>`;
});

const hasError = ref(false);
const generateWidgetSnippet = async (isInitial = false) => {
  isLoading.value = true;
  const { data } = await WidgetApiRequests.getTimelineWidgetCodeSnippet();
  if (data) {
    codeSnippet.value = data;
    codeSnippet.value = codeSnippet.value.replace(
      "attachTo: 'ScurriTrackPlusTimeline'",
      "attachTo: '<add-ID-here>'",
    );
    if (isInitial) {
      await invalidateCompanyState();
    }
  } else {
    hasError.value = true;
  }
  isLoading.value = false;
};

watchEffect(async () => {
  if (timelineWidgetEnabled.value && !codeSnippet.value)
    await generateWidgetSnippet();
});

const { mappedEnvToDomain } = useDomainMapping();
const isNonProdCodeSnippet = computed(() => mappedEnvToDomain !== PROD_ENV);

const copySnippet = () => {
  changeStateOnClick();
  navigator.clipboard.writeText(codeSnippet.value);
};
</script>

<style scoped lang="scss">
.widget-page-content {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 32px;
  flex-direction: row;
  gap: 72px;
  display: flex;

  @media screen and (max-width: 1280px) {
    gap: 48px;
    padding-left: 0;
  }
}

.widget-instructions__container {
  display: flex;
  max-width: 376px;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 1280px) {
    max-width: 344px;
  }
}

.widget-instructions__header {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 12px;
}

.widget-instructions__icon-container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.widget-instructions__icon {
  color: #1fb256;
  font-size: 20px;
}

.widget-instructions__header-text {
  color: #1f2a4e;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-instructions__text {
  max-width: 376px;
  width: fit-content;
  font-size: 14px;
  line-height: 21px;
}

.p-divider.p-divider-horizontal {
  margin: 0;
  padding: 0;
}

.snippet-code__container {
  display: flex;
  padding: 32px;
  flex: 1 0 0;
  margin: 0;
}

.snippet-code__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.snippet-code__header {
  color: #495057;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.snippet-code__code-container {
  border-radius: 4px;
  border: 1px solid #dadee3;
  background: #f7f8f9;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: auto;
  padding: 0 24px 24px;
  margin: 0;
  max-height: 344px;

  @media screen and (max-height: 740px) {
    max-height: 328px;
  }
}

.snippet-code__code {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  align-self: stretch;
  white-space: pre-wrap;
}

.snippet-code__button {
  margin-left: auto;
  flex-shrink: 0;
}

ol {
  padding-left: 16px;
}

pre {
  white-space: pre-wrap;
  margin-bottom: 0;
  font-size: 14px;
}

:deep(pre code.hljs) {
  padding: 0;
}

:deep(.hljs) {
  background: unset;
}

:deep(.error-bar) {
  width: 100%;
}

.widget-page-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
</style>

import { useQuery, useQueryClient } from "@tanstack/vue-query";
import CompanyApiRequests from "@/services/companyRequests";
import { ref } from "vue";
import { QUERY_KEYS } from "@/queryKeys";

export const useCompanyStateManagement = () => {
  const queryClient = useQueryClient();
  const isPrefetchComplete = ref(false);

  const prefetchCompanyState = async () => {
    await queryClient.prefetchQuery({
      queryKey: QUERY_KEYS.companyState,
      queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
      staleTime: 600000, // 10 minutes
    });

    const state = queryClient.getQueryState(QUERY_KEYS.companyState);
    isPrefetchComplete.value = !!state && state.status === "success";
  };

  const invalidateCompanyState = async () => {
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyState,
    });
  };

  // Synchronous check for feature flag based on cached data.
  // Use this when the flag is unlikely to change.
  // If the cache is empty, return 'false' (assuming the flag is disabled by default).
  const fetchAndVerifyFlag = (featureName) => {
    const companyStateCache = queryClient.getQueryData(QUERY_KEYS.companyState);
    if (companyStateCache) {
      return companyStateCache.featureFlags.includes(featureName);
    }
    return false;
  };

  const asyncFetchAndVerifyFlag = async (featureName) => {
    try {
      const companyStateCache = await queryClient.ensureQueryData({
        queryKey: QUERY_KEYS.companyState,
        queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
        onError: () => false,
      });
      if (companyStateCache) {
        return companyStateCache.featureFlags.includes(featureName);
      }
    } catch {
      return false;
    }
    return false;
  };

  return {
    isPrefetchComplete,
    prefetchCompanyState,
    invalidateCompanyState,
    fetchAndVerifyFlag,
    asyncFetchAndVerifyFlag,
  };
};

export const useQueryCompanyState = () => {
  return useQuery({
    queryKey: QUERY_KEYS.companyState,
    queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
    staleTime: 600000, // 10 minutes
    refetchOnMount: true,
  });
};

import { useCompanyStateManagement } from "@/queries";

const FEATURE_GTM_SANDBOX = "feature_gtm_sandbox";
const FEATURE_GTM_PROD = "feature_gtm_prod";

const FEATURE_MAP = {
  [FEATURE_GTM_SANDBOX]: {
    scriptPath: "/js/gtm-sandbox.js",
    managerUrl: "https://www.googletagmanager.com/ns.html?id=GTM-KT6VXZ6",
  },
  [FEATURE_GTM_PROD]: {
    scriptPath: "/js/gtm-prod.js",
    managerUrl: "https://www.googletagmanager.com/ns.html?id=GTM-5PTD669",
  },
};

const injectGtmScript = (featureName) => {
  const gtmScript = document.createElement("script");
  document.head.insertBefore(gtmScript, document.head.firstElementChild);
  gtmScript.src = FEATURE_MAP[featureName].scriptPath;
};

const injectGtmNoScriptIframe = (featureName) => {
  const noscript = document.createElement("noscript");
  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", FEATURE_MAP[featureName].managerUrl);
  iframe.setAttribute("height", "0");
  iframe.setAttribute("width", "0");
  iframe.setAttribute("style", "display:none;visibility:hidden");
  noscript.appendChild(iframe);
  document.body.insertBefore(noscript, document.body.firstElementChild);
};

export const useGtmScriptInjector = () => {
  const { asyncFetchAndVerifyFlag } = useCompanyStateManagement();

  const injectGtm = async () => {
    let feature_name = "";

    if (await asyncFetchAndVerifyFlag(FEATURE_GTM_SANDBOX)) {
      feature_name = FEATURE_GTM_SANDBOX;
    } else if (await asyncFetchAndVerifyFlag(FEATURE_GTM_PROD)) {
      feature_name = FEATURE_GTM_PROD;
    } else {
      return null;
    }
    injectGtmScript(feature_name);
    injectGtmNoScriptIframe(feature_name);
  };

  return { injectGtm };
};

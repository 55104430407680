<template>
  <aside class="nav-bar" data-cy="side-navigation">
    <nav class="nav" aria-label="navigation">
      <div class="nav-section">
        <div class="sidebar-brand mx-auto mb-2">
          <img
            class="d-block mx-auto logo"
            src="../assets/img/Scurri-Track-Plus-Logo.svg"
            alt="Scurri Track Plus Logo"
            @click="redirectToMain"
          />
        </div>
        <Menu
          id="primary-links"
          v-model:expandedKeys="expandedKeys"
          class="w-100"
          :model="primaryLinks"
        />
      </div>
      <div class="nav-section">
        <Divider />
        <Menu id="secondary-links" class="w-100" :model="secondaryLinks" />
      </div>
    </nav>
  </aside>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import Menu from "primevue/menu";
import Divider from "primevue/divider";
import { useAuthStore } from "@/store/authStore";
import { useToastService } from "@/services/toast/utils";
import { useFeatureFlagManager } from "@/features";
import { useAnalyticsQueryClient } from "@/queriesAnalytics";

const router = useRouter();

const expandedKeys = ref({});
const primaryLinks = ref([
  {
    key: "10",
    disabled: true,
    label: "Shipments",
    items: [
      {
        label: "Active Shipments",
        icon: "pi pi-truck",
        to: { name: "ListShipments" },
      },
      {
        label: "Past Shipments",
        icon: "pi pi-history",
        to: { name: "ListPastShipments" },
      },
    ],
  },
  {
    key: "11",
    disabled: true,
    label: "Tracking",
    items: [
      {
        label: "Branded Page",
        icon: "pi pi-desktop",
        to: { name: "ListTrackingPages" },
      },
      {
        label: "Widget",
        icon: "pi pi-code",
        to: { name: "TrackingWidget" },
      },
    ],
  },
  {
    key: "20",
    disabled: true,
    label: "Notifications",
    items: [
      {
        label: "Automations",
        icon: "fas fa-custom-workflow",
        to: { name: "ListNotificationWorkflows" },
      },
      {
        label: "Emails",
        icon: "pi pi-envelope",
        to: { name: "NotificationsPage" },
      },
    ],
  },
]);

const authStore = useAuthStore();
const { logOut } = authStore;

const { displayErrorGenericToast } = useToastService();

const secondaryLinks = ref([
  {
    label: "Help",
    icon: "pi pi-question-circle",
    url: "https://support.scurri.co.uk/hc/en-us/sections/15277269219346-How-Tos",
    target: "_blank",
  },
  {
    label: "Settings",
    icon: "pi pi-cog",
    to: { name: "Settings" },
  },
  {
    label: "Sign Out",
    icon: "pi pi-sign-out",
    command: async () => {
      const { error } = await logOut();
      if (!error) await router.push({ path: "/pre-login" });
      else displayErrorGenericToast();
    },
  },
]);

const { fetchAnalyticsDashboards } = useAnalyticsQueryClient();
const { isAnalyticsEnabled } = useFeatureFlagManager();

onMounted(async () => {
  if (await isAnalyticsEnabled()) {
    const data = await fetchAnalyticsDashboards();
    if (Array.isArray(data) && data.length) {
      const analyticsMenu = getAnalyticsMenu(data);
      primaryLinks.value.push(analyticsMenu);
    }
  }

  for (let item of primaryLinks.value) {
    expandedKeys.value[item.key] = true;
  }
});

const redirectToMain = () => {
  router.push("/");
};

const getAnalyticsMenu = (data) => {
  return {
    key: "30",
    disabled: true,
    label: "Analytics",
    items: data.map((d) => ({
      label: d.dashboardName,
      icon: "pi pi-desktop",
    })),
  };
};
</script>
<style scoped lang="scss">
@import "../scss/colors.scss";

:deep(.p-menu) {
  background: $dark-blue;
}

.logo {
  background: $mid-blue;

  &:hover {
    cursor: pointer;
  }
}
</style>

import { httpRequest } from "@/services/apiRequests";

class AnalyticsApiRequests {
  static async getDashboardList() {
    const { data } = await httpRequest.get("analytics/dashboards/");
    return data.map((d) => ({
      dashboardName: d.dashboard_name,
      slug: d.slug,
    }));
  }
}

export default AnalyticsApiRequests;
